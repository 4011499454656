import React, { Component } from 'react'
import {Button} from '@material-ui/core'

export default class Donate extends Component {
  render() {
    return (
     
         <Button href="/apply" className="navTag">Apply
          </Button>

    )
  }
}