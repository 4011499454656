import React, { Component } from 'react'

export default class Contact extends Component {
  render() {
    return (
      <>
      <h1 style={{textAlign: 'center'}}>Contact</h1>
      <p>
        If you would like to contact the Erika Pankow Scholarship fund, you can email <b>erikapankowscholarshipfund.org</b>. You can also visit us on the facebook page "Erika Pankow Scholarship Fund."
      </p>
      </>
    )
  }
}
